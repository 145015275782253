import Modal from '../../node_modules/bootstrap/js/dist/modal';

const modalCover = document.querySelector('#modalCover');
if (modalCover) {
    const storedCoverId = localStorage.getItem('coverId');
    const coverId = modalCover.getAttribute('data-cover-id');

    if (coverId !== storedCoverId) {
        const cover = new Modal('#modalCover');
        cover.show();

        document.querySelector('[data-bs-dismiss="modal"]').addEventListener('click', () => {
            localStorage.setItem('coverId', coverId);
        });
    }
}
